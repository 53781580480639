//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import './App.css';
import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppRoutes from './Router';
import { config } from './Config';

import { blue, orange } from '@mui/material/colors';

const defaultTheme = {
  palette: {
    primary: {
      main: orange[800],
    },
    secondary: {
      main: blue[800],
    },
    text: {
      primary: '#888888',
      secondary: '#666666',
    },
    leftSidebar: {
      main: '#ffffff',
      dark: blue[800],
      secondary: '#ffffff',
      text: '#222222',
    },
    topNavbar: {
      main: '#222222',
      secondary: '#6a6f48',
      dark: '#6a6f48',
      text: '#ffffff',
      search: '#ffffff',
      uploadButton: orange[800],
      icon: '#ffffff',
    },
    bottomNavbar: {
      main: '#222222',
      secondary: blue[800],
      text: '#FFFFFF',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    inlineFilter: {
      lineHeight: 1.8,
      fontSize: 19,
      fontWeight: 'bold',
    },
  },
};

const selectedTheme = config?.REACT_APP_THEME
  ? config.REACT_APP_THEME
  : defaultTheme;

const theme = createTheme(selectedTheme);

function App() {
  useEffect(() => {
    document.title = config.REACT_APP_NAME;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
