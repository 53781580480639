//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styles from './Sidebar.module.css';

import { useTheme } from '@mui/material/styles';

import RosmLogo from './RosmLogo';

import {
  mainListItems,
  rosmListItems,
  csrListItems,
  simulationListItems,
} from './ListItems';

import { config } from '../app/Config';

export const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function Sidebar({ user, open, handleDrawerClick, setDrawerOpen }) {
  const navigate = useNavigate();

  const { palette } = useTheme();

  const canViewROSM = true;
  let canViewCSR = false;
  user.roles.forEach((element) => {
    if (element.startsWith('CSR') || element === 'ADMINISTRATOR') {
      canViewCSR = true;
    }
  });

  const showSimulation = config.REACT_APP_SHOW_SIM;

  const [page, setPage] = useState(window.location.pathname);
  useEffect(() => {
    setPage(window.location.pathname);
  });

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: palette.leftSidebar.main,
          color: palette.leftSidebar.text,
        },
      }}
      variant='permanent'
      open={open}
      className={styles.sidebar}
      onClick={() => setDrawerOpen(false)}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            width: '100%',
            transition: 'opacity 0.85s ease-out',
            cursor: 'pointer',
            ...(!open && { opacity: 0 }),
          }}
          onClick={() => navigate('/')}
        >
          <RosmLogo />
        </div>
        <IconButton onClick={handleDrawerClick}>
          <ChevronLeftIcon className='adminNavBar' />
        </IconButton>
      </Toolbar>
      <Divider />
      <List dense={true} component='nav'>
        {mainListItems(page, palette)}
        <Divider sx={{ my: 1 }} />
        {canViewROSM && (
          <>
            {rosmListItems(page, palette)}
            <Divider sx={{ my: 1 }} />
          </>
        )}
        {showSimulation && (
          <>
            {simulationListItems(page, palette)}
            <Divider sx={{ my: 1 }} />
          </>
        )}
        {canViewCSR && (
          <>
            {csrListItems(page, palette)}
            <Divider sx={{ my: 1 }} />
          </>
        )}
      </List>
    </Drawer>
  );
}

export default Sidebar;
