/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSimulationPlugins } from '../../helpers/api/simulation';
import SimulationPluginList from './components/SimulationPluginList';

import { Header, MissingContent, Loading } from '../../components/Pages/index';

const SIM_PLUGIN_ACTIONS = {
  FETCH: 'FETCH',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * @param {*} state
 * @param {*} action
 * @returns
 */
const contentReducer = (state, action) => {
  switch (action.type) {
    case SIM_PLUGIN_ACTIONS.ROSM_PACKAGES_ACTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIM_PLUGIN_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        contentList: action.data,
      };
    }
    case SIM_PLUGIN_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  contentList: [],
  loading: false,
  loaded: false,
  error: null,
};

function SimulationPluginListPage() {
  const [state, dispatch] = useReducer(contentReducer, initialState);
  const { contentList, loading, error, loaded } = state;
  let navigate = useNavigate();

  const getList = async () => {
    try {
      let response = await getSimulationPlugins();
      const data = await response.json();
      const dataResults = data?.results || [];
      if (response.status === 200) {
        dispatch({ type: SIM_PLUGIN_ACTIONS.SUCCESS, data: dataResults });
        return;
      }
      dispatch({
        type: SIM_PLUGIN_ACTIONS.ERROR,
        error: response.error,
      });
    } catch (error) {
      dispatch({ type: SIM_PLUGIN_ACTIONS.ERROR, error: 'error' });
    }
  };

  useEffect(() => {
    if (!loading && !loaded && !error) {
      dispatch({ type: SIM_PLUGIN_ACTIONS.FETCH });
      getList();
    }
  }, []);

  return (
    <div>
      <div>
        {error && <h4>error</h4>}
        {loading ? (
          <Loading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Header title={'Simulation Plugins'.toUpperCase()} />
            {contentList.length === 0 && loaded && <MissingContent />}
            {contentList.length > 0 && (
              <SimulationPluginList rows={contentList} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SimulationPluginListPage;
