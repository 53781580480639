/*
 * (C) 2022 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getRosmPackageById,
  updateRosmPackageById,
} from '../../../helpers/api/rosmRequests';
import { PackageContent } from '@rosm/rosm-ng-components/dist/components/PageContent';
import { MissingContent, Loading } from '../../../components/Pages/index';
import { Context, canEditContent } from '../../../helpers';
import { uploadContentImage, deleteImage } from '../../../helpers/api/images';
import { config } from '../../../app/Config';
import { getCategories } from '../../../helpers/api/category';
import {
  GET_CATEGORIES,
  ADD_CATEGORIES,
  SET_ERRORS,
} from '../../../redux/actions/categories';
import categoriesReducer from '../../../redux/reducers/categories';

const ROSM_PACKAGES_ACTIONS = {
  FETCH: 'FETCH',
  FETCH_UPDATED: 'FETCH_UPDATED',
  UPDATED: 'UPDATED',
  UPDATE_ERROR: 'UPDATE_ERROR',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NO_SUCH_CONTENT: 'NO SUCH CONTENT',
};

const ERROR_LOADING = 'error loading';
const ERROR_SAVING = 'error saving';
const NO_SUCH_CONTENT = 'no content available';

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const rosmPackageReducer = (state, action) => {
  switch (action.type) {
    case ROSM_PACKAGES_ACTIONS.FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROSM_PACKAGES_ACTIONS.FETCH_UPDATED: {
      return {
        ...state,
      };
    }
    case ROSM_PACKAGES_ACTIONS.UPDATED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        rosmPackage: action.data,
      };
    }
    case ROSM_PACKAGES_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        rosmPackage: action.data,
      };
    }
    case ROSM_PACKAGES_ACTIONS.UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        rosmPackage: action.data,
      };
    }
    case ROSM_PACKAGES_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    case ROSM_PACKAGES_ACTIONS.NO_SUCH_CONTENT: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

const initialRosmPackageDetailsState = {
  rosmPackage: null,
  loading: false,
  loaded: false,
  error: null,
};

const initialCategoriesState = {
  categories: [],
  loading: false,
  loaded: false,
  error: null,
};

function PackagesDetails({ user }) {
  const { setNotificationSnackBarMessage } = useContext(Context);
  const navigate = useNavigate();
  const [reducerState, dispatch] = useReducer(
    rosmPackageReducer,
    initialRosmPackageDetailsState
  );

  const { rosmPackage, loading, error, loaded } = reducerState;
  const _bk = { ...rosmPackage };
  const { packageId } = useParams();

  const getPackage = async () => {
    dispatch({ type: ROSM_PACKAGES_ACTIONS.FETCH });
    try {
      let response = await getRosmPackageById(packageId);
      for (const pair of response.headers.entries()) {
        const key = pair[0];
        const val = pair[1];
        if (key === 'content-length' && val === '0') {
          dispatch({
            type: ROSM_PACKAGES_ACTIONS.NO_SUCH_CONTENT,
            error: NO_SUCH_CONTENT,
          });
          return;
        }
      }
      const packageResponse = await response.json();
      const data = packageResponse.result;
      if (response.status === 200) {
        dispatch({ type: ROSM_PACKAGES_ACTIONS.SUCCESS, data });
        return;
      } else {
        dispatch({
          type: ROSM_PACKAGES_ACTIONS.ERROR,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      dispatch({ type: ROSM_PACKAGES_ACTIONS.ERROR, error: ERROR_LOADING });
    }
  };

  const updatePackage = async (packageContent) => {
    dispatch({ type: ROSM_PACKAGES_ACTIONS.FETCH_UPDATED });
    try {
      let response = await updateRosmPackageById(packageId, packageContent);
      const data = await response.json();

      if (response.status === 200 && data.status) {
        dispatch({ type: ROSM_PACKAGES_ACTIONS.UPDATED, data: packageContent });
        setNotificationSnackBarMessage(true, 'Package Updated', 'success');
        return;
      }
      dispatch({
        type: ROSM_PACKAGES_ACTIONS.UPDATE_ERROR,
        error: data?.error ? data.error : response.error,
        data: _bk,
      });
      setNotificationSnackBarMessage(
        true,
        `Package Update: ${data.error.length} Error(s)`,
        'error'
      );
    } catch (error) {
      dispatch({ type: ROSM_PACKAGES_ACTIONS.ERROR, error: ERROR_SAVING });
      setNotificationSnackBarMessage(true, 'Package Update Error', 'error');
    }
  };

  const handleUploadFile = async (file) => {
    const data = new FormData();
    data.append('file', file);
    data.append('contentType', 'RosmPackage');
    const resp = await uploadContentImage(rosmPackage._id.toString(), data);

    if (resp.status === 400) {
      setNotificationSnackBarMessage(true, 'Package Update Error', 'error');
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(true, 'Package Image Added', 'success');
    }
    return resp;
  };

  const handleImageDelete = async (imageUrl) => {
    const imageId = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    const resp = await deleteImage(rosmPackage._id.toString(), imageId);
    if (resp.status === 400) {
      setNotificationSnackBarMessage(true, 'Package Update Error', 'error');
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(true, 'Package Image Deleted', 'success');
    }
  };

  const handleImageChanges = async (newImages) => {
    dispatch({
      type: ROSM_PACKAGES_ACTIONS.UPDATED,
      data: { ...rosmPackage, images: newImages },
    });
  };

  const linkOffsiteButtonClicked = (url) => {
    window.open(url);
  };

  const getCategoriesData = async () => {
    categoriesDispatch({ type: GET_CATEGORIES });
    try {
      const response = await getCategories();
      const data = await response.json();
      if (response.status === 200 && data) {
        categoriesDispatch({ type: ADD_CATEGORIES, data });
      } else {
        dispatch({
          type: SET_ERRORS,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      categoriesDispatch({
        type: SET_ERRORS,
        error: 'error',
      });
    }
  };

  const [categoriesState, categoriesDispatch] = useReducer(
    categoriesReducer,
    initialCategoriesState
  );
  const [categoryMappings, setCategoryMappings] = useState(null);

  useEffect(() => {
    getCategoriesData();
  }, []);

  const { categories } = categoriesState;

  useEffect(() => {
    let rosmCategories = [];
    categories.map((category) => {
      rosmCategories.push({
        title: category.name,
        label: category.name,
        value: category.categoryId,
        status: 1,
        description: category.name,
        img: `${category.primary.toLowerCase()}.png`,
      });
    });
    setCategoryMappings(rosmCategories);
  }, [categories]);

  useEffect(() => {
    if (!loading && !loaded && !error) {
      getPackage();
    }
  }, []);

  const handleEvent = (event, newContent) => {
    updatePackage(newContent);
  };

  const handleInternalLinkClick = (link) => {
    navigate(link);
  };

  if (error) {
    if (error === NO_SUCH_CONTENT) {
      return <MissingContent title={'The requested package does not exist.'} />;
    }
    return <h4>error</h4>;
  }

  if (!rosmPackage) {
    <MissingContent />;
  }

  if (
    !loaded ||
    loading ||
    !categoriesState.loaded ||
    categoriesState.loading ||
    !categoryMappings ||
    categoryMappings.length === 0
  ) {
    return <Loading />;
  }

  const showEditing = canEditContent(user, rosmPackage.userId);

  return (
    <PackageContent
      linkOffsiteButtonClicked={linkOffsiteButtonClicked}
      handleInternalLinkClick={handleInternalLinkClick}
      imagePath={config.PUBLIC_URL}
      content={rosmPackage}
      categories={categories}
      categoryMapping={categoryMappings}
      showEditing={showEditing}
      handleEvent={handleEvent}
      handleUploadFile={handleUploadFile}
      handleImageDelete={handleImageDelete}
      handleImageChanges={handleImageChanges}
    />
  );
}

export default PackagesDetails;
