/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSimulationPlugins,
  updateSimulationPluginById,
  deleteSimulationPluginById,
} from '../../helpers/api/simulation';
import { MissingContent, Loading } from '../../components/Pages/index';
import { Context, SIMULATION_PLUGIN_URL, canEditContent } from '../../helpers';
import { SimulationPluginContent } from '@rosm/rosm-ng-components/dist/components/PageContent';
import { uploadContentImage, deleteImage } from '../../helpers/api/images';
import { config } from '../../app/Config';
const SIM_PLUGIN_ACTIONS = {
  FETCH: 'FETCH',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
};

const ERROR_LOADING = 'error loading';
const ERROR_SAVING = 'error saving';
const NO_SUCH_CONTENT = 'no content available';

/**
 * @param {*} state
 * @param {*} action
 * @returns
 */
const contentReducer = (state, action) => {
  switch (action.type) {
    case SIM_PLUGIN_ACTIONS.FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIM_PLUGIN_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        content: action.data,
      };
    }
    case SIM_PLUGIN_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    case SIM_PLUGIN_ACTIONS.UPDATED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        content: action.data,
      };
    }
    case SIM_PLUGIN_ACTIONS.DELETED: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  content: [],
  loading: false,
  loaded: false,
  error: null,
};

function SimulationPluginDetailsPage({ user }) {
  const { setNotificationSnackBarMessage } = useContext(Context);
  const [state, dispatch] = useReducer(contentReducer, initialState);
  const { content, loading, error, loaded } = state;
  const _bk = { ...content };
  let navigate = useNavigate();
  const { simulationPluginId } = useParams();

  const getItem = async () => {
    dispatch({ type: SIM_PLUGIN_ACTIONS.FETCH });
    try {
      let response = await getSimulationPlugins(simulationPluginId);
      const data = await response.json();
      const dataResult = data?.result || [];
      if (response.status === 200) {
        dispatch({ type: SIM_PLUGIN_ACTIONS.SUCCESS, data: dataResult });
        return;
      }
      dispatch({
        type: SIM_PLUGIN_ACTIONS.ERROR,
        error: response.error,
      });
    } catch (error) {
      dispatch({ type: SIM_PLUGIN_ACTIONS.ERROR, error: 'error' });
    }
  };

  useEffect(() => {
    if (!loading && !loaded && !error) {
      getItem();
    }
  }, []);

  const linkOffsiteButtonClicked = (url) => {
    window.open(url);
  };

  // if (error) {
  //   if (error === NO_SUCH_CONTENT) {
  //     return <MissingContent title={'The requested package does not exist.'} />;
  //   }
  //   return <h4>error</h4>;
  // }

  const updateSimulationPlugin = async (SimulationPluginContent) => {
    dispatch({ type: SIM_PLUGIN_ACTIONS.FETCH });
    try {
      let response = await updateSimulationPluginById(
        simulationPluginId,
        SimulationPluginContent
      );
      const data = await response.json();

      if (response.status === 200 && data.status) {
        dispatch({
          type: SIM_PLUGIN_ACTIONS.UPDATED,
          data: SimulationPluginContent,
        });
        setNotificationSnackBarMessage(true, 'Simulation Updated', 'success');
        return;
      }
      dispatch({
        type: SIM_PLUGIN_ACTIONS.UPDATE_ERROR,
        error: data?.error ? data.error : response.error,
        data: _bk,
      });
      setNotificationSnackBarMessage(
        true,
        `Simulation Update: ${data.error.length} Error(s)`,
        'error'
      );
    } catch (error) {
      dispatch({ type: SIM_PLUGIN_ACTIONS.ERROR, error: ERROR_SAVING });
      setNotificationSnackBarMessage(true, 'Simulation Update Error', 'error');
    }
  };

  const deleteSimulationPlugin = async (simulationPluginId) => {
    dispatch({ type: SIM_PLUGIN_ACTIONS.FETCH });
    try {
      let response = await deleteSimulationPluginById(simulationPluginId);
      const data = await response.json();

      if (response.status === 200 && data.status) {
        dispatch({
          type: SIM_PLUGIN_ACTIONS.DELETED,
        });
        setNotificationSnackBarMessage(true, 'Simulation Deleted', 'success');
        navigate('/simulationPlugins');
        return;
      }
      dispatch({
        type: SIM_PLUGIN_ACTIONS.UPDATE_ERROR,
        error: data?.error ? data.error : response.error,
        data: _bk,
      });
      setNotificationSnackBarMessage(
        true,
        `Simulation Delete: ${data.error.length} Error(s)`,
        'error'
      );
    } catch (error) {
      dispatch({ type: SIM_PLUGIN_ACTIONS.ERROR, error: ERROR_SAVING });
      setNotificationSnackBarMessage(true, 'Simulation Delete Error', 'error');
    }
  };

  const handleEvent = (event, newContent) => {
    updateSimulationPlugin(newContent);
  };

  const handleUploadFile = async (file) => {
    const data = new FormData();
    data.append('file', file);
    data.append('contentType', 'SimulationPlugin');
    const resp = await uploadContentImage(content._id.toString(), data);

    if (resp.status === 400) {
      setNotificationSnackBarMessage(true, 'Simulation Update Error', 'error');
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(true, 'Simulation Image Added', 'success');
    }
    return resp;
  };

  const handleImageDelete = async (imageUrl) => {
    const imageId = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    const resp = await deleteImage(content._id.toString(), imageId);
    if (resp.status === 400) {
      setNotificationSnackBarMessage(
        true,
        'Simulation Plugin Update Error',
        'error'
      );
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(
        true,
        'Simulation Plugin Image Deleted',
        'success'
      );
    }
  };

  const handleImageChanges = async (newImages) => {
    dispatch({
      type: SIM_PLUGIN_ACTIONS.UPDATED,
      data: { ...content, images: newImages },
    });
  };

  if (!content) {
    <MissingContent />;
  }

  if (!loaded || loading) {
    return <Loading />;
  }

  const showEditing = canEditContent(user, content.userId);
  return (
    <SimulationPluginContent
      linkOffsiteButtonClicked={linkOffsiteButtonClicked}
      imagePath={config.PUBLIC_URL}
      content={content}
      showEditing={showEditing}
      handleEvent={handleEvent}
      handleUploadFile={handleUploadFile}
      handleImageDelete={handleImageDelete}
      handleDeleteEvent={deleteSimulationPlugin}
      handleImageChanges={handleImageChanges}
    />
  );
}

export default SimulationPluginDetailsPage;
