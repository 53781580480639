//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useReducer,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

/** Global Context & Helper Functions */
import {
  Context,
  getRequestData,
  logout,
  onEvents,
  setIsValid,
  REACT_APP_SERVER,
  getSdaMdaDocumentFields,
  onSdaMdaAnnotatedPdfLoad,
} from '../../helpers';

import {
  reducer as requestsReducer,
  initialState as requestsInitialState,
  loadData as requestsLoadData,
  ACTIONS as requestsACTIONS,
} from '../../helpers/reducers/Requests';

/** Component Imports from rosm-ng-components */
import ProfileMenu from '@rosm/rosm-ng-components/dist/components/ProfilePage/ProfileMenu.js';
import ProfileBody from '@rosm/rosm-ng-components/dist/components/ProfilePage/ProfileBody.js';

/** File Upload Imports */
import { postUploadFile, deleteFile } from '../../helpers/api/userRequests';
import { getUserDocuments } from '../../helpers/api/index';

import { CircularProgress } from '@mui/material';

/** User Documents */
const USER_DOCUMENTS_ACTIONS = {
  FETCH_USER_DOCUMENTS: 'FETCH_USER_DOCUMENTS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const userDocumentsListReducer = (state, action) => {
  switch (action.type) {
    case USER_DOCUMENTS_ACTIONS.FETCH_USER_DOCUMENTS: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    }
    case USER_DOCUMENTS_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        documents: action.data,
      };
    }
    case USER_DOCUMENTS_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialUserDocuments = {
  documents: [],
  loading: false,
  loaded: false,
  error: null,
};

/**
 * ROSM Profile Page
 */
const Profile = ({ user }) => {
  const [searchParams] = useSearchParams();
  let incomingUrlParams = {};
  for (const [key, value] of searchParams.entries()) {
    incomingUrlParams[key] = value;
  }

  const scrollToItem = incomingUrlParams?.scroll;
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    setSnackbarMessage,
    setNotificationSnackBarMessage,
    dispatch,
    setStoredShowTour,
    setToken,
    config,
  } = useContext(Context);

  const {
    onSetSupportRespond,
    onSupportRequest,
    onCsrRequest,
    onEmailChangeRequest,
    onOrganizationChangeRequest,
    onApiRequest,
    onSdaMdaRequest,
    onSdaMdaSubmitRequestForPreview,
  } = onEvents;

  const [showClosedSupport, setShowClosedSupport] = useState(false); // Pass Show Closed Support Rows Bool
  const [sdaMdaFormFields, setSdaMdaFormFields] = useState();
  const [previewPdfPath, setPreviewPdfPath] = useState();
  const [pdf, setPdf] = useState();

  const [userDocuments, _dispatch] = useReducer(
    userDocumentsListReducer,
    initialUserDocuments
  );
  const [requestState, requestsDispatch] = useReducer(
    requestsReducer,
    requestsInitialState
  );
  const { requestsData, loaded } = requestState;

  /* Assign references for smooth scrolling in menu */
  const contact = useRef();
  const general = useRef();
  const emailChange = useRef();
  const organizationChange = useRef();
  const permissions = useRef();
  const api = useRef();
  const sdaMda = useRef();
  const csr = useRef();
  const password = useRef();
  const twoFactor = useRef();
  const contactSupport = useRef();
  const documentsRef = useRef();
  const faq = useRef();
  const tour = useRef();
  const STANDARD = 'Standard';

  const getUserDocumentList = async () => {
    _dispatch({
      type: USER_DOCUMENTS_ACTIONS.FETCH_USER_DOCUMENTS,
    });
    try {
      const data = await getUserDocuments();

      if (data && data.documents) {
        _dispatch({
          type: USER_DOCUMENTS_ACTIONS.SUCCESS,
          data: data.documents,
        });
        return;
      }
      _dispatch({
        type: USER_DOCUMENTS_ACTIONS.ERROR,
        error: 'no documents available',
      });
    } catch (error) {
      _dispatch({
        type: USER_DOCUMENTS_ACTIONS.ERROR,
        error: 'error',
      });
    }
  };

  useEffect(() => {
    getUserDocumentList();
    requestsLoadData(requestsDispatch);
  }, []);

  const reloadRequests = () => {
    requestsLoadData(requestsDispatch);
  };

  const updateRequests = async (requestsUpdated) => {
    const updateRequests = async () => {
      requestsDispatch({ type: requestsACTIONS.FETCH_REQUESTS });
      requestsDispatch({
        type: requestsACTIONS.REQUESTS,
        data: {
          ...requestsData,
          requests: requestsUpdated,
        },
      });
    };
    updateRequests();
  };

  /* Handle the support response to update the context */
  const handleOnSetSupportRespond = (supportRequestId, message) => {
    const req = async () => {
      const requestsUpdated = await onSetSupportRespond(
        supportRequestId,
        message,
        requestsData.requests
      );
      await updateRequests(requestsUpdated);
    };
    req();
  };

  /* Handle the support request to update the context */
  const handleOnSupportRequest = (requestTopic, requestMessage) => {
    const req = async () => {
      await onSupportRequest(requestTopic, requestMessage);
      const requestsUpdated = await getRequestData();
      await updateRequests(requestsUpdated.fetched);
      return true;
    };
    return req();
  };

  /* Handle the csr request to update the context */
  const handleOnCsrRequest = (requestMessage) => {
    const req = async () => {
      await onCsrRequest(requestMessage);
      const requestsUpdated = await getRequestData();
      await updateRequests(requestsUpdated.fetched);
      return true;
    };
    return req();
  };

  /* Handle Email Change Request */
  const handleEmailChangeRequest = (
    requestCurrentEmail,
    requestNewEmail,
    requestMessage
  ) => {
    const req = async () => {
      await onEmailChangeRequest(
        requestCurrentEmail,
        requestNewEmail,
        requestMessage
      );
      const requestsUpdated = await getRequestData();
      await updateRequests(requestsUpdated.fetched);
      return true;
    };
    return req();
  };

  /* Handle Organization Change Request */
  const handleOrganizationChangeRequest = (
    requestNewOrganization,
    requestMessage
  ) => {
    const req = async () => {
      await onOrganizationChangeRequest(requestNewOrganization, requestMessage);
      const requestsUpdated = await getRequestData();
      await updateRequests(requestsUpdated.fetched);
      return true;
    };
    return req();
  };

  /* Handle the api request to update the context */
  const handleOnApiRequest = (requestMessage) => {
    const req = async () => {
      await onApiRequest(requestMessage);
      const requestsUpdated = await getRequestData();
      await updateRequests(requestsUpdated.fetched); // Get all requests associated with the current user
      return true;
    };
    return req();
  };

  const handleFileUpload = (file, contentType) => {
    const req = async (file, contentType) => {
      try {
        const data = new FormData();
        data.append('file', file);
        data.append('contentType', contentType);
        await postUploadFile(data);
        setNotificationSnackBarMessage(true, 'Document Uploaded', 'success');
        getUserDocumentList();
        return { complete: true };
      } catch (error) {
        return { complete: false };
      }
    };
    return req(file, contentType);
  };

  const handleFileDelete = (fileId) => {
    const req = async () => {
      await deleteFile(fileId);
      setNotificationSnackBarMessage(true, 'Document Deleted', 'success');
      getUserDocumentList();
      return { complete: true };
    };
    return req();
  };

  /**
   * Set the user to be logged out if they choose to not stay logged in
   */
  const handleLogout = () => {
    const logoutUser = async () => {
      const result = await logout(setIsValid, dispatch, true);
      if (!result) {
        setNotificationSnackBarMessage(true, 'Unable to Logout', 'error');
        return;
      }
      setToken({});
    };
    logoutUser();
  };

  const handleSetIsValid = (data) => {
    setIsValid(data, dispatch);
  };

  const onActivateTour = () => {
    setStoredShowTour(true);
  };

  const onOpenSdaMdaRequest = (_id = '') => {
    // If _id is passed then get the existing field values and load into the form and set the save request to be current ID and submit to be an update to existing ID
    const req = async () => {
      const result = await getSdaMdaDocumentFields(_id);
      setSdaMdaFormFields({
        activeRequestId: _id,
        activeTopic: result.activeTopic,
        fields: result.fields,
        area: STANDARD,
      });
    };
    req();
  };

  const handleOnSdaMdaSubmitRequestForPreview = (form) => {
    const req = async () => {
      const result = await onSdaMdaSubmitRequestForPreview(form);
      setPreviewPdfPath(result);
    };
    return req();
  };

  /* Handle the sda/mda request to update the context */
  const handleOnSdaMdaRequest = (requestMessage) => {
    const req = async () => {
      const requestsUpdated = await onSdaMdaRequest(requestMessage);
      const requestsResult = await getRequestData();
      await updateRequests(requestsResult); // Get all requests associated with the current user
      return requestsUpdated;
    };
    return req();
  };

  const handleOnSdaMdaAnnotatedPdfLoad = (
    documentId = 'AGVRA',
    type = 'Standard'
  ) => {
    const req = async () => {
      const result = await onSdaMdaAnnotatedPdfLoad(documentId, type);
      setPdf(result);
    };
    return req();
  };

  const localOnEvents = {
    ...onEvents,
    onSetSupportRespond: handleOnSetSupportRespond,
    onSupportRequest: handleOnSupportRequest,
    onCsrRequest: handleOnCsrRequest,
    onEmailChangeRequest: handleEmailChangeRequest,
    onOrganizationChangeRequest: handleOrganizationChangeRequest,
    onApiRequest: handleOnApiRequest,
    onSdaMdaRequest: handleOnSdaMdaRequest,
    onHandleUpload: handleFileUpload,
    onFileDelete: handleFileDelete,
    onActivateTour: onActivateTour,
    onOpenSdaMdaRequest: onOpenSdaMdaRequest,
    onSdaMdaSubmitRequestForPreview: handleOnSdaMdaSubmitRequestForPreview,
    onSdaMdaAnnotatedPdfLoad: handleOnSdaMdaAnnotatedPdfLoad,
  };
  return (
    <>
      <Grid container spacing={2} wrap='wrap'>
        <Grid item xs={6} sm={4} md={4} lg={2}>
          <ProfileMenu
            area='profile'
            contact={contact}
            general={general}
            documentsRef={documentsRef}
            emailChange={emailChange}
            organizationChange={organizationChange}
            permissions={permissions}
            api={api}
            sdaMda={sdaMda}
            csr={csr}
            password={password}
            twoFactor={twoFactor}
            contactSupport={contactSupport}
            faq={faq}
            tour={tour}
          />
        </Grid>
        <Grid item xs={6} sm={8} md={8} lg={10}>
          {user && loaded ? (
            <>
              <ProfileBody
                user={user}
                documentsRef={documentsRef}
                requestItems={requestsData.requests}
                loaded={loaded}
                showClosedSupport={showClosedSupport}
                setShowClosedSupport={setShowClosedSupport}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                setNotificationSnackBarMessage={setNotificationSnackBarMessage}
                snackbarMessage={snackbarMessage}
                setSnackbarMessage={setSnackbarMessage}
                contact={contact}
                documents={userDocuments.documents}
                general={general}
                emailChange={emailChange}
                organizationChange={organizationChange}
                permissions={permissions}
                api={api}
                sdaMda={sdaMda}
                csr={csr}
                password={password}
                twoFactor={twoFactor}
                contactSupport={contactSupport}
                faq={faq}
                tour={tour}
                logout={handleLogout}
                setIsValid={handleSetIsValid}
                onEvents={localOnEvents}
                requests={requestsData.requests}
                setRequests={updateRequests}
                serverUrl={REACT_APP_SERVER}
                scrollToItem={scrollToItem}
                sdaMdaDocuments={sdaMdaFormFields}
                area={'Standard'}
                setPreviewPdfPath={setPreviewPdfPath}
                previewPdfPath={previewPdfPath}
                reloadRequests={reloadRequests}
                pdf={pdf}
                setPdf={setPdf}
                config={config}
                citizenshipOptions={config.citizenshipOptions}
              />
            </>
          ) : (
            <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
