//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useContext } from 'react';
import Box from '@mui/material/Box';

/* ROS-M Component Imports */
import AuthLoginForm from '@rosm/rosm-ng-components/dist/components/AuthLoginForm/AuthLoginForm.js';
import {
  FORM_MESSAGES,
  insensitiveStringCompare,
} from '@rosm/rosm-ng-components/dist/components/utilities';
/** Global Context */
import {
  POST,
  GET,
  BACKEND_HOST,
  setIsValid,
  logout,
  Context,
} from '../helpers';

import ACTIONS from '../helpers/context/contextActions';
import SupportFooter from '../components/SupportFooter';
/**
 * Authentication Form
 * Posts the Authentication Form to Server and provides a true/false. Provides onAuthLogin Dependency to AuthLoginForm
 * IN:
 * - email: email address from authentication form
 * - password: password from authentication form
 * OUT:
 * - true/false (boolean): based on valid token response from server
 */
function AuthLayout({ dispatch }) {
  const { setNotificationSnackBarMessage, setToken, config } =
    useContext(Context);

  /**
   * Logs User In
   * @param {string} email
   * @param {string} password
   */
  const onAuthLogin = async (email, password) => {
    const result = await POST(`${BACKEND_HOST}/user/login`, {
      email: email,
      password: password,
    });
    const data = await result.json();
    const { message, missingVerification, isVerified } = data;
    if (
      insensitiveStringCompare(
        message,
        FORM_MESSAGES.FAILURE_TO_PROCESS_LOGIN
      ) ||
      insensitiveStringCompare(
        message,
        FORM_MESSAGES.LOGIN_ATTEMPTS_RATE_LIMITED
      )
    ) {
      return data;
    }
    if (insensitiveStringCompare(message, FORM_MESSAGES.LOGIN_SUCCESSFUL)) {
      if (!isVerified) {
        return data;
      }
      if (isVerified && data.success) {
        // TODO
        dispatch({
          type: ACTIONS.TWOFA_STATUS,
          data: true,
        });
        window.location.reload();
      } else {
        // TODO
        dispatch({
          type: ACTIONS.TWOFA_STATUS,
          data: missingVerification.twoFaEnabled,
        });
      }
      return data;
    }
    return false;
  };

  /**
   * Set the user to be logged out if they choose to not stay logged in
   */
  const handleLogout = () => {
    const logoutUser = async () => {
      const result = await logout(setIsValid, dispatch, true);
      if (!result) {
        setNotificationSnackBarMessage(true, 'Unable to Logout', 'error');
        return;
      }
      setToken({});
    };
    logoutUser();
  };

  /**
   * Resend the Email Verification Email
   * @param {string} userId
   */
  const onResendEmailVerification = async (userId) => {
    const result = await GET(
      `${BACKEND_HOST}/user/resendEmailVerification/${userId}`
    );
    const data = await result.json();
    return data.valid;
  };

  /**
   * Checks 2-FA Configuration
   */
  const onTwoFaConfigCheck = async () => {
    const result = await POST(`${BACKEND_HOST}/user/twoFaConfigCheck`);
    const data = await result.json();
    return data;
  };

  /**
   * Send a 2-FA Enabled State Once QR Code Scanned
   */
  const onTwoFaConfigSet = async () => {
    const result = await POST(`${BACKEND_HOST}/user/twoFaConfigSet`);
    const data = await result.json();
    return data.active;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AuthLoginForm
        onAuthLogin={onAuthLogin}
        onLogout={handleLogout}
        resetLink={'/resetPassword'}
        onResendEmailVerification={onResendEmailVerification}
        onTwoFaConfigCheck={onTwoFaConfigCheck}
        onTwoFaConfigSet={onTwoFaConfigSet}
        infoEmailAddress={config.infoEmailAddress}
        systemTitle={config.systemTitle}
        systemReference={config.systemReference}
      />
      <SupportFooter />
    </Box>
  );
}

export default AuthLayout;
