/* Header Content Type for Including */
export const headers = {
  'Content-Type': 'application/json',
};

/* GET (View) the JSON Results from the Server using a GET Fetch */
export const GET = async (url) => {
  return await fetch(url, {
    method: 'GET',
    headers: headers,
    credentials: 'include',
  });
};

/**
 * Post file object to endpoint
 * @param {string} url
 * @param {object} body
 * @returns
 */
export const POST_FILE = async (url, body) => {
  const requestFormat = {
    method: 'POST',
    headers: {},
    body: body,
    credentials: 'include',
  };
  return await fetch(url, requestFormat);
};

export const POST_SDAMDA_FILE = async (url, body) => {
  const requestFormat = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      ...body,
    }),
    credentials: 'include',
  };
  await fetch(url, requestFormat)
    .then((response) => response.blob())
    .then((blob) => {
      const previewObject = document.querySelector('object');
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: 'application/pdf' })
      );
      previewObject.data = url;
      return url;
    });
};

export const POST_SDAMDA_ANNOTATED_FILE = async (url, body) => {
  const requestFormat = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      ...body,
    }),
    credentials: 'include',
  };
  await fetch(url, requestFormat)
    .then((response) => response.blob())
    .then((blob) => {
      const previewObject = document.querySelector('object');
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: 'application/pdf' })
      );
      previewObject.data = url;
      return url;
    });
};

/* POST (Create) Form Elements to Specified URL to Server using POST Fetch and Return Results */
export const POST = async (url, body) => {
  const requestFormat = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
    credentials: 'include',
  };
  return await fetch(url, requestFormat);
};

/* POST (Create) Form Elements to Specified URL to Server using POST Fetch and Return Results in JSON Format */
export const POST_RESULT = async (url, body) => {
  const requestFormat = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
    credentials: 'include',
  };
  const result = await fetch(url, requestFormat);
  return await result.json();
};

/* PUT (Update) Form Elements to Specified URL to Server using PUT Fetch and Return Results */
export const PUT = async (url, body) => {
  const requestFormat = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(body),
    credentials: 'include',
  };
  return await fetch(url, requestFormat);
};

/* DELETE (Delete) a Record Using URL Query Elements Containing the ID */
export const DELETE = async (url) => {
  const requestFormat = {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  };
  return await fetch(url, requestFormat);
};
