//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import {
  GET,
  SDA_MDA_URL,
  POST_SDAMDA_FILE,
  POST_SDAMDA_ANNOTATED_FILE,
} from './index';
import urlJoin from 'url-join';

export const getSdaMdaDocumentFields = async (_id = '', showAdmin = '') => {
  try {
    const url = urlJoin(SDA_MDA_URL, 'formFields', _id, showAdmin);
    const result = await GET(url);
    if (result.ok) {
      const data = await result.json();
      return data.results;
    }
    throw result.error;
  } catch (err) {
    return {
      error: err,
    };
  }
};

export const getSdaMdaDocuments = async () => {
  try {
    const result = await GET(`${SDA_MDA_URL}/getDocuments`);
    if (result.ok) {
      const data = await result.json();
      return data.results;
    }
    throw result.error;
  } catch (err) {
    return {
      error: err,
    };
  }
};

export const onSdaMdaSubmitRequestForPreview = async (form) => {
  try {
    const result = await POST_SDAMDA_FILE(
      `${SDA_MDA_URL}/createDocument`,
      form
    );
    if (!result) throw Error('Failed to Create PDF');
  } catch (err) {
    return {
      error: err,
    };
  }
};

export const onSdaMdaAnnotatedPdfLoad = async (
  documentId,
  type = 'Standard'
) => {
  try {
    const result = await POST_SDAMDA_ANNOTATED_FILE(
      `${SDA_MDA_URL}/getAnnotatedDocument`,
      { documentId: documentId, type: type }
    );
    if (!result) throw Error('Failed to Get PDF');
    return result;
  } catch (err) {
    return {
      error: err,
    };
  }
};
