/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer } from 'react';
import InstantiationList from './components/InstantiationList';
import { getRosmInstantiationList } from '../../../helpers/api';

import {
  Header,
  Loading,
  MissingContent,
} from '../../../components/Pages/index';

const ROSM_INSTANTIATION_ACTIONS = {
  FETCH_ROSM_INSTANTIATIONS: 'FETCH_ROSM_INSTANTIATIONS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const rosmInstantiationReducer = (state, action) => {
  switch (action.type) {
    case ROSM_INSTANTIATION_ACTIONS.FETCH_ROSM_INSTANTIATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        rosmInstantiationList: action.data,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialRosmInstantiationState = {
  rosmInstantiationList: [],
  loading: false,
  loaded: false,
  error: null,
};

/**
 * Instantiation List Page
 */
function InstantiationListPage() {
  const [state, dispatch] = useReducer(
    rosmInstantiationReducer,
    initialRosmInstantiationState
  );

  const { rosmInstantiationList, loading, error, loaded } = state;

  useEffect(() => {
    if (!loading && !loaded && !error) {
      dispatch({ type: ROSM_INSTANTIATION_ACTIONS.FETCH_ROSM_INSTANTIATIONS });
      // TODO move elsewhere
      const getList = async () => {
        try {
          let response = await getRosmInstantiationList();
          const instantiationResponse = await response.json();
          const data = instantiationResponse.results;
          if (response.status === 200) {
            dispatch({ type: ROSM_INSTANTIATION_ACTIONS.SUCCESS, data });
            return;
          }
          dispatch({
            type: ROSM_INSTANTIATION_ACTIONS.ERROR,
            error: data?.error ? data.error : response.error,
          });
        } catch (error) {
          dispatch({ type: ROSM_INSTANTIATION_ACTIONS.ERROR, error: 'error' });
        }
      };

      getList();
    }
  }, []);

  return (
    <>
      {error && <h4>error</h4>}
      {loading ? (
        <Loading />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <Header title={'Instantiations'.toUpperCase()} />
          {rosmInstantiationList.length === 0 && loaded && <MissingContent />}
          {rosmInstantiationList.length > 0 && (
            <InstantiationList rows={rosmInstantiationList} />
          )}
        </>
      )}
    </>
  );
}

export default InstantiationListPage;
