/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getRosmInstantiationById,
  updateRosmInstantiationById,
} from '../../../helpers/api/rosmRequests';
import { InstantiationContent } from '@rosm/rosm-ng-components/dist/components/PageContent';
import { MissingContent, Loading } from '../../../components/Pages/index';
import { uploadContentImage, deleteImage } from '../../../helpers/api/images';
import { Context, canEditContent } from '../../../helpers';
import { config } from '../../../app/Config';

const ROSM_INSTANTIATION_ACTIONS = {
  FETCH: 'FETCH',
  FETCH_UPDATED: 'FETCH_UPDATED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NO_SUCH_CONTENT: 'NO SUCH CONTENT',
  UPDATED: 'UPDATED',
};

const ERROR_LOADING = 'error loading';
const ERROR_SAVING = 'error saving';
const NO_SUCH_CONTENT = 'no content available';

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const rosmInstanceReducer = (state, action) => {
  switch (action.type) {
    case ROSM_INSTANTIATION_ACTIONS.FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.FETCH_UPDATED: {
      return {
        ...state,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.UPDATED: {
      return {
        ...state,
        instantiation: action.data,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        instantiation: action.data,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    case ROSM_INSTANTIATION_ACTIONS.NO_SUCH_CONTENT: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: true,
      };
    }
    default: {
      return state;
    }
  }
};

const initialRosmInstantiationDetailsState = {
  instantiation: null,
  loading: false,
  loaded: false,
  error: null,
};

function InstantiationDetails({ user }) {
  const { setNotificationSnackBarMessage } = useContext(Context);

  const [state, dispatch] = useReducer(
    rosmInstanceReducer,
    initialRosmInstantiationDetailsState
  );
  const [packageCount, setPackageCount] = useState(0);
  const { instantiation, loading, error, loaded } = state;
  const _bk = { ...instantiation };
  const { instantiationId } = useParams();
  const navigate = useNavigate();

  // TODO move elsewhere
  const getInstantiation = async () => {
    dispatch({ type: ROSM_INSTANTIATION_ACTIONS.FETCH });
    try {
      const response = await getRosmInstantiationById(instantiationId);
      for (const pair of response.headers.entries()) {
        const key = pair[0];
        const val = pair[1];
        if (key === 'content-length' && val === '0') {
          dispatch({
            type: ROSM_INSTANTIATION_ACTIONS.NO_SUCH_CONTENT,
            error: NO_SUCH_CONTENT,
          });
          return;
        }
      }
      const result = await response.json();
      const data = result.data;
      setPackageCount(result.packageCount);
      if (response.status === 200) {
        dispatch({ type: ROSM_INSTANTIATION_ACTIONS.SUCCESS, data });
        return;
      }
      dispatch({
        type: ROSM_INSTANTIATION_ACTIONS.ERROR,
        error: data?.error ? data.error : response.error,
      });
    } catch (error) {
      dispatch({
        type: ROSM_INSTANTIATION_ACTIONS.ERROR,
        error: ERROR_LOADING,
      });
    }
  };

  const updateInstantation = async (instantiationContent) => {
    dispatch({ type: ROSM_INSTANTIATION_ACTIONS.FETCH_UPDATED });
    try {
      let response = await updateRosmInstantiationById(
        instantiationId,
        instantiationContent
      );
      const data = await response.json();

      if (response.status === 200 && data.status) {
        dispatch({
          type: ROSM_INSTANTIATION_ACTIONS.UPDATED,
          data: instantiationContent,
        });
        setNotificationSnackBarMessage(
          true,
          'Instantiation Updated',
          'success'
        );
        return;
      }
      dispatch({
        type: ROSM_INSTANTIATION_ACTIONS.UPDATE_ERROR,
        error: data?.error ? data.error : response.error,
        data: _bk,
      });
      setNotificationSnackBarMessage(
        true,
        `Instantiation Update: ${data.error.length} Error(s)`,
        'error'
      );
    } catch (error) {
      dispatch({ type: ROSM_INSTANTIATION_ACTIONS.ERROR, error: ERROR_SAVING });
      setNotificationSnackBarMessage(
        true,
        'Instantiation Update Error',
        'error'
      );
    }
  };

  const handleEvent = (event, newContent) => {
    updateInstantation(newContent);
  };

  const handleInternalLinkClick = (link) => {
    navigate(link);
  };

  const handleUploadFile = async (file) => {
    const data = new FormData();
    data.append('file', file);
    data.append('contentType', 'RosmInstantiation');
    const resp = await uploadContentImage(instantiation._id.toString(), data);

    if (resp.status === 400) {
      setNotificationSnackBarMessage(
        true,
        'Instantiation Update Error',
        'error'
      );
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(
        true,
        'Instantiation Image Added',
        'success'
      );
    }
    return resp;
  };

  const handleImageDelete = async (imageUrl) => {
    const imageId = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    const resp = await deleteImage(instantiation._id.toString(), imageId);
    if (resp.status === 400) {
      setNotificationSnackBarMessage(
        true,
        'Instantiation Update Error',
        'error'
      );
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(
        true,
        'Instantiation Image Deleted',
        'success'
      );
    }
  };

  const handleImageChanges = async (newImages) => {
    dispatch({
      type: ROSM_INSTANTIATION_ACTIONS.UPDATED,
      data: { ...instantiation, images: newImages },
    });
  };

  const linkOffsiteButtonClicked = (url) => {
    window.open(url);
  };

  useEffect(() => {
    if (!loading && !loaded && !error) {
      getInstantiation();
    }
  }, []);

  if (error) {
    if (error === NO_SUCH_CONTENT) {
      return (
        <MissingContent title={'The requested instantiation does not exist.'} />
      );
    }
    return <h4>error</h4>;
  }

  if (!instantiation) {
    <MissingContent />;
  }

  if (!loaded || loading) {
    return <Loading />;
  }

  const showEditing = canEditContent(user, instantiation.userId);
  console.log('INST: ', instantiation);
  return (
    <>
      {instantiation && (
        <InstantiationContent
          linkOffsiteButtonClicked={linkOffsiteButtonClicked}
          handleInternalLinkClick={handleInternalLinkClick}
          imagePath={config.PUBLIC_URL}
          content={instantiation.result}
          packageCount={packageCount}
          showEditing={showEditing}
          handleEvent={handleEvent}
          handleUploadFile={handleUploadFile}
          handleImageDelete={handleImageDelete}
          handleImageChanges={handleImageChanges}
        />
      )}
    </>
  );
}

export default InstantiationDetails;
